// Core
import React, { useEffect, useContext } from "react"
import TransitionLink from "gatsby-plugin-transition-link"
// InView detector
import { InView } from "react-intersection-observer"

import { ContextDispatch } from "./../context/Context"

import Circle from "./../../static/images/svg/button-circle.inline.svg"
import Arrow from "./../../static/images/svg/arrow.inline.svg"

// Styles
import btnStyles from "./../components/button/button.module.scss"

// Components
import SEO from "./../components/seo/seo"

const NotFoundPage = () => {
  const storeDispatch = useContext(ContextDispatch)

  useEffect(() => {
    const header = () => {
      storeDispatch({ type: "HEADER_LIGHT" })
    }
    header()
  }, [storeDispatch])

  const exitAnim = (exit, node) => {
    storeDispatch({ type: "SWIPE_IN" })
  }

  const entryAnim = (entry, node) => {
    storeDispatch({ type: "SWIPE_OUT" })
  }

  return (
    <React.Fragment>
      <SEO title={`404 Page`} />
      <InView triggerOnce={true} threshold={0.2}>
        {({ inView, ref, entry }) => (
          <section
            style={{
              background: ` #2431b3`,
              color: `#f9f8f2`,
              height: `100vh`,
            }}
            ref={ref}
            inview={inView.toString()}
          >
            <h1
              style={{
                position: `absolute`,
                top: `50%`,
                left: `50%`,
                transform: `translateX(-50%) translateY(-50%)`,
              }}
            >
              Page Not Found
            </h1>
            <TransitionLink
              to={`/`}
              className={`button button--light  ${btnStyles.link}`}
              exit={{
                trigger: ({ exit, node }) => exitAnim(exit, node),
                length: 1,
                zIndex: 2,
              }}
              entry={{
                trigger: ({ entry, node }) => entryAnim(entry, node),
                delay: 1,
                length: 1,
                zIndex: 1,
              }}
              style={{
                position: `absolute`,
                top: `50%`,
                left: `50%`,
                transform: `translateX(-50%) translateY(-50%) translateY(8rem)`,
              }}
            >
              <span>
                <span className={btnStyles.circle}>
                  <Circle />
                  <span className={btnStyles.arrowWrap}>
                    <span className={btnStyles.arrow}>
                      <Arrow />
                    </span>
                    <span className={btnStyles.arrow}>
                      <Arrow />
                    </span>
                  </span>
                </span>
                <span className={btnStyles.buttonText}>
                  <span>Go to Home Page</span>
                </span>
              </span>
            </TransitionLink>
          </section>
        )}
      </InView>
    </React.Fragment>
  )
}

export default NotFoundPage
